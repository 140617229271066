const homeUrl = [
  { url: 'https://i.imgur.com/Vne9jIo.jpeg', alt: 'Architecture Photographer in Bahrain, showing the Bahrain Institute of Banking & Finance (BIBF) building.' },
  { url: 'https://i.imgur.com/nyhHSFs.jpeg', alt: 'Architecture Photographer in Bahrain, showing MS Centre by MSCEB.' },
  { url: 'https://i.imgur.com/uu0XjQq.jpeg', alt: '' },
  { url: 'https://i.imgur.com/9OBXg2y.jpeg', alt: '' },
  { url: 'https://i.imgur.com/4OGAhXL.jpeg', alt: '' },
  { url: 'https://i.imgur.com/9vXNF7M.jpeg', alt: '' },
  { url: 'https://i.imgur.com/nozlqry.jpeg', alt: '' },
  { url: 'https://i.imgur.com/LCIrECK.jpeg', alt: '' },
  { url: 'https://i.imgur.com/mjFEWDq.jpeg', alt: '' },
  { url: 'https://i.imgur.com/ClpfEpV.jpeg', alt: '' },
  { url: 'https://i.imgur.com/8m7WvpL.jpeg', alt: '' },
  { url: 'https://i.imgur.com/7bzelNw.jpeg', alt: '' },
];


const foodDrinksUrl = [
  { url: 'https://i.imgur.com/4KwS3jR.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/ns9FgSQ.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/UqddJPW.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/BEV4VZ3.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/mKftfEG.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/rd9nvag.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/FmPiZTC.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/JfTrLvC.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/0reWU2D.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/XvSjFdS.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/4bPKSbb.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/eFKDccB.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/6DEkBT2.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/URwTHc3.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/Eny9B0E.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/TIhN9MO.jpeg', alt: 'insert' },

  { url: 'https://i.imgur.com/ENiocIV.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/bIQIfwD.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/qsoabuj.jpeg', alt: 'insert' },
  { url: 'https://i.imgur.com/dSMealK.jpeg', alt: 'insert' }
];


const realEstateUrl = [
  { url: 'https://i.imgur.com/U5ovLcm.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/WUy3fmz.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/tOk6pac.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/tv0bd5x.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/IYVnwYY.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/eVbaEIV.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/05aZAMz.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/2tLgj6S.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/ONfAn2G.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/si1OJcG.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/IG3MGA3.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/faH3zhe.jpeg', alt: 'Real Estate Photographer in Bahrain' },
  { url: 'https://i.imgur.com/WD8w0cr.jpeg', alt: 'Real Estate Photographer in Bahrain' }
];



const interiorsUrl = [
  { url: 'https://i.imgur.com/EPgKBd6.jpeg', alt: 'Hospitality Photographer in Bahrain' },
  { url: 'https://i.imgur.com/1Rec7qQ.jpeg', alt: 'Interior Photographer in Khobar' },

  // Set C
  { url: 'https://i.imgur.com/jjx5GzB.jpeg', alt: 'Interior Photographer in Al Khobar' },
  { url: 'https://i.imgur.com/U32stXg.jpeg', alt: 'Interior Photographer in Bahrain' },

  // Set H
  { url: 'https://i.imgur.com/G7v9xzw.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/0UDYvcx.jpeg', alt: 'Interior Photographer in Bahrain' },

  // Set B
  { url: 'https://i.imgur.com/walJRMS.jpeg', alt: 'Hospitality Photographer in Bahrain' },
  { url: 'https://i.imgur.com/3wzUGE1.jpeg', alt: 'Hospitality Photographer in Bahrain' },

  // Set E
  { url: 'https://i.imgur.com/O5b4Uib.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/2CnyjJB.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/18H4tNI.jpeg', alt: 'Interior Photographer in Bahrain' },

  // Set A (Shuffled internally)
  { url: 'https://i.imgur.com/7bzelNw.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/4OGAhXL.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/LRX38Rw.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/9OBXg2y.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/9vXNF7M.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/nozlqry.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/mjFEWDq.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/uu0XjQq.jpeg', alt: 'Interior Photographer in Bahrain' },

  // Set F
  { url: 'https://i.imgur.com/9Rnpc7R.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/Oad9tRi.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/mBW9hF2.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/hYYDaei.jpeg', alt: 'Interior Photographer in Bahrain' },

  // Set D
  { url: 'https://i.imgur.com/EhQrM5W.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/uXyBFnI.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/8rI7nax.jpeg', alt: 'Interior Photographer in Bahrain' },

  // Set G
  { url: 'https://i.imgur.com/OP6OX1H.jpeg', alt: 'Interior Photographer in Bahrain' },
  { url: 'https://i.imgur.com/ALBMVqk.jpeg', alt: 'Interior Photographer in Bahrain' }
];









module.exports = {interiorsUrl, foodDrinksUrl, homeUrl, realEstateUrl }
