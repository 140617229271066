import React, { useState } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import './App.css'
import { handleRightClick } from './AppUtility'
import NavBar from './Pages/NavBar'
import Me from './Pages/Me'
import Contact from './Pages/Contact'
import Home from './Pages/Home'
import RealEstate from './Pages/RealEstate'
import FoodDrinks from './Pages/FoodDrinks'
import Interiors from './Pages/Interiors'


export default function App() {
  document.addEventListener('contextmenu', handleRightClick);

  return (
      <div className="container">
        <div class="flexbox-1">
          <NavBar />
        </div>
        <div class="flexbox-2">
            <Routes>
              <Route path="/" element={<Home />} />
              {/*<Route index element={<Home />} />*/}
              <Route path="/food-and-drinks" element={<FoodDrinks />} />
              <Route path="/interiors" element={<Interiors />} />
              

              <Route path="/real-estate-portfolio" element={<RealEstate />} />
              <Route path="/me" element={<Me />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="*" element={<Home />} />
            </Routes>
        </div>
      </div>
  )
}