import { interiorsUrl } from './ImageUrl.js'
import InteriorsMasonry from './Masonry/InteriorsMasonry.js'

export default function Interiors() {
  return (
    <div>
      <InteriorsMasonry interiorsUrl={interiorsUrl} columnCount="2" gap="5"></InteriorsMasonry>
    </div>
  )
}
