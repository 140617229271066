import { homeUrl } from './ImageUrl.js'
import HomeMasonry from './Masonry/HomeMasonry.js'

export default function Home() {
  return (
    <div>
      <HomeMasonry homeUrl={homeUrl} columnCount="2" gap="5"></HomeMasonry>
    </div>
  )
}
