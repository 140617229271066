import { foodDrinksUrl } from './ImageUrl.js'
import FoodDrinksMasonry from './Masonry/FoodDrinksMasonry.js'

export default function FoodDrinks() {
  return (
    <div>
      <FoodDrinksMasonry foodDrinksUrl={foodDrinksUrl} columnCount="2" gap="5"></FoodDrinksMasonry>
    </div>
  )
}
