import { Link, useMatch, useResolvedPath } from "react-router-dom"
import instagram from "./instagramSvg.svg"

export default function Navbar() {
   
    return (
        <div class="navbar">
            <div class="title">
                <Link to="/"><b><h2>hassen arante</h2></b></Link>
            </div>

            <h1 class="h1-text">
                Bahrain Interior Photographer
            </h1>
    
            <div class="small-text">  
                <CustomLink to="/interiors"><h3>Interior Photography</h3></CustomLink>
                <CustomLink to="/food-and-drinks"><h3>Food + Drinks Photography</h3></CustomLink>
                <CustomLink to="/real-estate-portfolio"><h3>Real Estate Photography</h3></CustomLink>
                <br></br>
                <CustomLink to="/me">About Me</CustomLink>
                <CustomLink to="/contact">Contact Me</CustomLink>
                <div class="ig-flex">
                    <a href="https://www.instagram.com/hassenarante/" target="_blank">
                        <img height={20} src={instagram}></img>
                    </a>
                </div>
            </div>
        </div>
    )   
}

function CustomLink ({to, children, ...props}){
    const resolvedPath = useResolvedPath(to)
    const isActive = useMatch({ path: resolvedPath.pathname, end: true })
    return (
        <li className={isActive ? "active" : ""}>
            <Link to={to} {...props}>{children}</Link>
        </li>
    )
}